//サイトレイアウト構造を記述

#container {}
#main {
  overflow: hidden;
}
#contents {
  padding: 0;
  @include mq-max(sm) {
    width: $full-size;
    padding: 0;
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 50px 15px 90px;
  @media screen and (max-width:$contentWidth + $contentPadding) {
    padding: 50px $contentPadding 90px;
  }
  @include mq-max(sm) {
    padding: 50px $contentPadding/2 90px;//お好みで変更
  }
  &__inner {
    padding: 0 0 60px;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
  }
  &--medium {
    max-width: 88%;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &--small {
    max-width: 50%;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  // 画面幅いっぱい
  &--full {
    max-width: 100%;
  }
  // 上部余白を大きくとるセクション
  &--ptl {
    padding-top: 100px;
    @include mq-max(xs) {
      // max-width: 100%;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  [class*="grid"] {
    margin-bottom: -1 * $contentPadding;
  }
}

//background full width
@mixin bg_scss() {
  @include mq-max(content) {
    margin-bottom: 60px;;
  }
  &>.l-section {
    @include mq-max(content) {
      padding-top: 60px;
    }
  }
}

.l-section--keyColor {
  background-color: $keyColor;
  @include bg_scss();
}
.l-section--bgGreen {
  background: linear-gradient($white, $bg-green);
  @include bg_scss();
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
  &--only-sm{
    @include mq-max(sm) {
      text-align: center !important;
    }
  }
}
.u-taR {
  text-align: right !important;
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  display

///////////////////////////////////////////////////////////////////////////////////////////////////////

.u-br{
  display: none;
  &--xs{
    @include mq-max(xs) {
      display: inline-block;
    }
  }
  &--md{
    @include mq-max(md) {
      display: inline-block;
    }
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
// .anchorReposition {
//   padding-top: $headerHeight + 10px;
//   margin-top: -1 * $headerHeight - 10px;
//   @include mq-max(md) {
//     padding-top: $headerHeight-sp + 10px;
//     margin-top: -1 * $headerHeight-sp - 10px;
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

// タイトルをカウントするセクション
.l-count-section{
  counter-reset: count;
}


//互い違い
.l-alternate {
//  margin-bottom: 0 + (-1 * $contentPadding);
  &__wrap {
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}


// 3column box
.l-3col-box {
  & > .c-box{
    border-left: none;
    border-top: none;
    border-bottom: none;
    border-right: 1px solid $line;
    &:last-child{
      border-right: none;
    }
    @include mq-max(sm) {
      border-right: none;
      border-bottom: 1px solid $line;
      &:last-child{
        border-bottom: none;
      }
    }
  }
}


//2カラムレイアウト
.l-col2 {
  position: relative;
  //画像端付き
  &--side {
    overflow: hidden;
    .l-col2__img {
      width: 100%;
      width: calc(100% / 2 - 22px);
      height: 510px;
      flex: 0 0 calc(100% / 2 - 22px);
      // flex: 0 0 50%;
      background-position: inherit;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 10;
      @include mq-max(sm) {
        width: calc(100% / 2 - 22px);
        flex: initial;
        width: 100%;
        height: 300px;
      }
    }
    .l-col2__detail {

    }
    .l-col2__block {
      padding-bottom: 100px;
      &:last-child{
        padding-bottom: 0px;
      }
      @include mq-max(sm) {
        padding-bottom: 40px;
      }
      &--reverse {
        .l-col2__detail {
          padding: 0 50px 0 0;
          @include mq-max(content) {
            padding-right: 30px;
            padding-left: $contentPadding;
          }
          @include mq-max(sm) {
            padding-left: $contentPadding / 2;
            padding-bottom: 40px;
          }
        }
        .l-col2__txt{
          padding-left: 15px;
          padding-right: 43px;
          @include mq-max(sm) {
            padding-right: 15px;
          }
        }
      }
    }
  }

  &__img {
    width: 50%;
    @include mq-max(sm) {
      width: 100%;
      // margin-bottom: 30px;
    }
    img {
      display: block;
      width: 100%;
      @include mq-max(sm) {
        max-width: 450px;
        margin: 0 auto;
      }
    }
  }
  &__detail {
    padding: 0 0 0 50px;
    width: 50%;
    padding-bottom: 40px;
    @include mq-max(sm) {
      width: 100%;
      padding: 0 0 1.9em 0;
    }
    &--bg{ //背景に装飾
      position: relative;
      z-index: 1;
      &:before {
        content: '';
        display: block;
        background: url('/inc/image/common/bg_wave.png')no-repeat center / contain;
        width: 1217px;
        height: 600px;
        position: absolute;
        top: 50%;
        right: -185.5px;
        transform: translateY(-50%);
        z-index: -1;
        @include mq-max(sm) {
          width: 1217px * 0.7;
          height: 600px * 0.7;
        }
        @include mq-max(xs) {
          width: 1217px * 0.5;
          height: 600px * 0.5;
        }
      }
    }
  }
  &__wrap{
    padding-bottom: 133px;
    @include mq-max(sm) {
      padding-bottom: 20px;
    }
     &:last-child {
      padding-bottom: 0;
    }
    // タイトルが入っていた場合
    &-ttl{
      @include mq-max(sm) {
        padding-top: 40px;
      }
    }
  }
  &__block {
    display: flex;
    align-items: center;
    @include mq-max(sm) {
      flex-direction: column-reverse;
      padding-bottom: 30px;
      padding-top: 20px;
      border-bottom: 1px solid $line;
      &:first-child{
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
    .l-col2__txt{
      // max-width: 592px;
      padding: 24px 15px;
      padding-left: 43px;
      @include mq-max(sm) {
        padding-left: 15px;
      }
    }
    &--reverse {
      flex-direction: row-reverse;
      @include mq-max(sm) {
        flex-direction: column-reverse;
      }
      .l-col2__detail {
        padding: 0 50px 0 0;
        @include mq-max(sm) {
          padding: 0;
        }
        &--bg{
          &:before{
            @include mq-min(sm) {
              right: -393px;
            }
          }
        }
      }
    }
    &--start{
      align-items: flex-start;
    }
  }
  &__btn {
    @include mq-max(sm) {
      text-align: center;
    }
  }
  // タイトルが入っているsection
  &__ttl-section{
    width: 100%;
    max-width: 1138px;
    padding: 0 15px;
    @include mq-max(sm) {
      padding: 0;
    }
  }

  // btnが2つ並ぶ時
  &__btn-wrap{
    display: flex;
    justify-content: center;
    padding: 30px 0;
    @include mq-max(sm) {
      flex-direction: column;
      align-items: center;
    }
    & > .c-btn{
      @include mq-max(sm) {
        max-width: 100%;
      }
      &:first-child{
        margin-right: 30px;
        @include mq-max(sm) {
          margin-right: 0px;
        }
      }
      &:last-child{
        margin-bottom: 30px;
        @include mq-max(sm) {
          margin-bottom: 0px;
        }
      }
    }
  }
}

// CVエリア
.l-cvArea{
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  &__inner{
    padding: 60px 0 63px 0;
    text-align: center;
    color: $white;
    background: $keyColorDarker;
    &:before {
      content: "";
      background: url("../../inc/image/common/bg_wave.png");
      background-size: cover;
      background-position: center;
      width: 1667px;
      height: 933px;
      position: absolute;
      top: -221px;
      right: -201px;
      pointer-events: none;
      @include mq-max(md) {
        top: -221px;
        right: -400px;
      }
      @include mq-max(sm) {
        width: 1111px;
        height: 548px;
        top: -84px;
        right: -296px;
      }
    }
  }
}