.l-navigation {
//  background-color: $white;
  @include mq-max(md) {
    display: none;
    html.open &.active > ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  &.active {
    transform: translateY(0%);
  }
  &__inner {
    padding: 0;
    display: flex;
    @include mq-max(md) {
      display: block;
      height: calc(100vh - #{$headerHeight-sp});
      overflow-y: scroll;
    }
  }
  &__item {
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    @include mq-max(md) {
      margin-right: 0;
      border-top: 1px solid $line;
      &:last-child{
        border-bottom: 1px solid $line;
      }
    }
    a {
      @include mq-max(md) {
        background-color: $white;
      }
    }
    &>a {
      font-size: 15px;
      font-weight: 700;
      text-decoration: none;
      color: $textColor;
      display: inline-block;
      padding: 33px 15px;
      letter-spacing: .06em;
      @include mq-max(md) {
        display: block;
        padding: 12.5px 25.5px;
      }
      &:hover {
        color: $keyColor;
        &:before {
          width: 95%;
        }
      }
      &:before {
        content: '';
        display: block;
        width: 0%;
        height: 2px;
        background-color: $keyColor;
        position: absolute;
        bottom: 0;
        left: (100% - 95%) / 2;
        transition: all $transition;
        @include mq-max(md) {
          content: none;
        }
      }
      &:after {
        @include mq-max(md) {
          @include fa('f105');
          position: absolute;
          top: 55%;
          right: 25.5px;
          transform: translateY(-50%);
          transition: all $transition;
        }
      }
      &.current {
        @include mq-max(md) {
          color: $keyColor;
        }
        &:before {
          width: 95%;
        }
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a{
        position: relative;
        &:after {
          @include mq-max(md) {
            @include fa('f067');
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
          }
        }
        &.has-dropdown {
          &:after {
            @include mq-max(md) {
              content: '\f068';
            }
          }
        }
      }
      &:hover {
        .l-dropDown {
          top: $headerHeight; // headerの高さ
          opacity: 1;
          visibility: visible;
          @include mq-max(md) {
            top: auto;
          }
          a {
            transition: color $transition,
                        height $transition;
            &:hover {
              color: $keyColor;
            }
          }
        }
      }
      .l-dropDown {
        position: absolute;
        top: $headerHeight - 30px; // headerの高さ - 30px
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        @include mq-max(md) {
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
        li {
          border-bottom: 1px solid $line;
          @include mq-max(md) {
            border-bottom: none;
          }
          &:last-child {
            border: none;
          }
          a {
            background-color: $white;
            padding: 10px 15px;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $textColor;
            width: 100%;
            @include mq-max(md) {
              line-height: 0;
              height: 0;
              padding: 0;
              opacity: 0;
              transition: height $transition,
                          opacity 0.4s,
                          padding $transition,
                          line-height $transition;
            }
          }
        }
      }
      &.is-opened {
        > .l-dropDown {
          @include mq-max(md) {
            opacity: 1;
            display: block;
            > li {
              border-bottom: 1px solid $line;
              &:first-child {
                border-top: 1px solid $line;
              }
              &:last-child {
                border-bottom: none;
              }
            }
            > li > a {
              opacity: 1;
              line-height: 1.5;
              height: 54px;
              padding: 15px 10px 15px 25px;
            }
          }
        }
      }
    }
  }
  // Gナビに付いているボタン
  &__btn-wrap{
    margin-left: 10px;
    @include mq-max(md) {
      margin-left: 0;
    }
  }
  &__btn{
    width: 88px;
    height: 88px;
    display: block;
    @include mq-max(md) {
      display: none;
    }
    &>a {
      font-weight: 700;
      font-size: 10px;
      letter-spacing: .06em;
      width: 100%;
      height: 88px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $bg;
      transition: .2s;
      border-left: 1px solid $line;
      &:hover{
        opacity: .7;
        transition: .2s;
      }
      &> span {
        position: relative;
        top: 13px;
        &:before{
          content: "";
          background-image: url("../../inc/image/common/ico_mail.png");
          padding: 14.5px;
          background-size: contain;
          position: absolute;
          top: -28px;
          left: 1rem;
        }
      }
    }
  }
  &__btn-sp{
    display: none;
    @include mq-max(md) {
      display: flex;
      justify-content: center;
      background: white;
      border-bottom: 1px solid $line;
      padding: 14.5px 16px 18px;
    }
    // 幅が広めのボタン(ハンバーガーnav用)
    > .c-btn{
      @include mq-max(md) {
        max-width: 328px;
        font-size: 14px;
        padding: 14px 30px 14px 20px;
      }
    }
  }
}
