///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  position: relative;
  display: block;
  width: 100%;
  max-width: 291px;
  border-radius: 4px;
  color: $white;
  background: $keyColor;
  box-shadow: $box-shadow;
  overflow: hidden;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: .06em;
  line-height: 1.45;
  padding: 19px 40px 19px 20px;
  // border: 1px solid $keyColor;
  text-align: center;
  margin-bottom: 30px;
  transition: color $transition,
              background-color $transition;
  backface-visibility:hidden;
  overflow:hidden;
  z-index: 1;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(sm) {
    max-width: 221px;
    font-size: 14px;
    padding: 14px 30px 14px 20px;
  }
  &:hover {
    color: $white;
    background-color: $keyColor;
    &:before{ //btnの中の円
      border-radius: 0;
      background-color: $keyColorDarker;
      transition: .2s;
      will-change: transform;
      transform: translate(-50%, -50%);
      // transform: translate(-50%, -50%);
    }
  }
  // ボタンに表示する文字を入れている
  >span {
    position: relative;
    display: inline-block;
    z-index: 10;
    &:after {
      @include fa('f054');
      position: absolute;
      top: 52.3%;
      right: -1em;
      transform: translateY(-50%);
      transition: right $transition;
    }
  }

  // 外部リンク
  &--target{
    &> span {
      &:after {
        @include fa('f24d');
        position: absolute;
        top: 52.3%;
        right: -1.2em;
        transform: translateY(-50%);
        transition: right $transition;
      }
    }
  }
  // PDF
  &--pdf{
    &> span {
      &:after {
        @include fa('f1c1');
        position: absolute;
        top: 52.3%;
        right: -1.2em;
        transform: translateY(-50%);
        transition: right $transition;
      }
    }
  }

  // ホバーで変化する円
  &:before {
    content: "";
    width: 101%;
    height: 300px;
    border-radius: 50%;
    background-color: $keyColorDark;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateY(-50%);
    transition: right $transition;
    transition: .2s;
    will-change: transform;
    @include mq-max(sm) {
      height: 200px;
    }
  }

  //PDF
  &[href$=".pdf"] {
    &:hover {
      &:after {
        // right: 16px;
        color: $white;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &:after {
      // color: $keyColor;
      content: none;
    }
    &:hover {
      &:after {
        // right: 16px;
        color: $white;
      }
    }
  }

  //テキスト量で長さ可変
  &__inline {
    display: inline-block;
    width: auto;
    max-width: inherit;
    @include mq-max(xs) {
      width: 100%;
    }
  }

  //小さいボタン
  &__small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  //ホバーで背景がスライド
  &__slide {
    position: relative;
    overflow: hidden;
    transition: all .4s ease;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $keyColor;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) .4s;
    }
    &:hover {
      background-color: #fff;
      color: #fff;
      &:before {
        transform: translateX(0%);
      }
    }
    &>span {
      position: relative;
      z-index: 1;
    }
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  PFWForm

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-btnArea{
  max-width: $contentWidth;
  margin: 40px auto;
  text-align: center;
  @include mq-max(md) {
    margin: 20px auto;
  }
  @include mq-max(sm) {
    width: $full-size;
    margin: 0 auto;
    padding: 10px 0;
  }
}

.c-btn {
  &.c-btnForm {
    display: inline-block;
    width: auto;
    min-width: $btn-width;
    padding: 20px 40px;
    margin-bottom: 10px;
    font-size: 130%;
    display: inline-block;
    background: $keyColor;
    text-align: center;
    border: $border-btn-width solid  $border-btn;
    color: $border-txt-color;
    transition: $transition;
    border-radius: $border-radius;
    text-decoration: none;
    position: relative;
    @include mq-max(sm) {
      display: inline-block;
      min-width: inherit;
      width: 100%;
      margin: 0 0 20px 0;
    }
    &:focus {
      outline: none;
    }

    &:hover{
      background: darken( $keyColor, 15% );
    }

    &.back{
      width: auto;
      line-height: 28px;
      min-width: inherit;
      background: $white;
      border: 1px solid #ccc;
      color: $black;
      font-size: 120%;
      padding-left: 60px;
      margin-right: 20px;
      @include mq-max(sm){
        font-size: 96%;
        min-width: inherit;
        width: 50%;
        max-width: 100%;
        margin: 0 auto;
        padding: 10px 20px;
      }

      &:hover{
        background: lighten( $gray, 15% );
      }

      &:before{
        @include fa('f053');
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        text-align: left;
        @include mq-max(xs) {
          left: 8px;
        }
      }
      &:after {
        content: none;
      }
    }
  }
}
