.c-topicspath{
  max-width: $contentWidth;
  width: 100%;
  margin: 0 auto;
  padding: 10px 15px;
  @include mq-max(sm){
    text-align: left;
  }
  li{
    display: inline-block;
    font-size: 16px;
    @include mq-max(sm){
      font-size: 0.7em;
    }
    &:after {
      @include fa('f105');
      display: inline-block;
      margin-left: 8px;
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
    > a {
      color: $textColor;
      &:not(.c-btn){
        border-bottom: 1px solid $textColor;
      }
    }
  }
}
