///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl{
  // h2見出しーーーーーーーーーーーーーーーーーーーーーーーーーー
  &-2{
    font-size: 32px;
    font-weight: 600;
    letter-spacing: .06em;
    line-height: 1.75;
    margin-bottom: 30px;
    z-index: 1;
    @include mq-max(md) {
      font-size: 24px;
    }
    // 背景にテキスト
    &--bgtxt{
      position: relative;
      margin-bottom: 60px;
      padding-top: 2em;
      @include mq-max(sm) {
        margin-bottom: 30px;
        padding-top: 1em;
      }
      & >.c-ttl__deco {
        width: 100%;
        position: absolute;
        color: $keyColorLighter;
        opacity: .2;
        font-family: $titleFont;
        font-size: 4em;
        font-weight: 500;
        // font-style: italic;
        top: -0.17em;
        line-height: 1;
        letter-spacing: .06em;
        left: 0;
        z-index: -1;
        padding: .06em 0;
        @include mq-max(sm) {
          font-size: 60px;
          top: -0.2em;
        }
      }
    }
  }
  // h3見出しーーーーーーーーーーーーーーーーーーーーーーーーーー
  &-3{
    font-size: 24px;
    letter-spacing: .06em;
    line-height: 1.75;
    font-weight: 700;
    margin-bottom: 30px;
    @include mq-max(md) {
      font-size: 20px;
      line-height: 1.5;
    }
    // 背景にテキスト
    &--bgtxt{
      position: relative;
      padding-top: 2em;
      z-index: 10;
      @include mq-max(sm) {
        margin-bottom: 24px;
      }
      & > .c-ttl__deco {
        width: 100%;
        position: absolute;
        color: $gray;
        opacity: .2;
        font-family: $titleFont;
        font-size: 4.16em;
        // font-style: italic;
        font-weight: 500;
        top: -0.2em;
        line-height: 1;
        letter-spacing: .12em;
        left: 0;
        // z-index: -1;
        @include mq-max(md) {
          font-size: 3em;
          top: 0em;
        }
      }
    }
  }

  // タイトル共通ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  &__txt{
    max-width: $contentWidth / 2;
    display: inline-block;
    z-index: 10;
    &--large{
      max-width: 100%;
    }
  }
  // 中央寄せ
  &--center {
    text-align: center;
    // 背景にテキスト装飾を持つh2
    // &.c-ttl-2--bgtxt {
    //   & >.c-ttl__deco {
    //     left: 50%;
    //     transform: translateX(-50%);
    //   }
    // }
    // 背景にテキスト装飾を持つh3
    // &.c-ttl-3--bgtxt {
    //   & >.c-ttl__deco {
    //     left: 50%;
    //     transform: translateX(-50%);
    //   }
    // }
  }
  // 下マージン大き目
  &--mbLarge{
    margin-bottom: 90px;
  }
  // 下マージン小さめ
  &--mbSmall{
    margin-bottom: 30px;
  }
  // カウント方式
  &--count {
    padding-left: 1.1em;
    & >.c-ttl__txt {
      position: relative;
      &:before{
        counter-increment: count;
        content:counter(count)".";
        position: absolute;
        left: -1.1em;
        color: $textColor;
      }
    }
  }
}
