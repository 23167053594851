//変数定義

/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/

//----------------------------------------------------------------
//    レイアウト設定
//----------------------------------------------------------------
$pcWidth         : 1366px;
$contentWidth    : 1138px;// 全体のコンテンツ幅
$contentPadding  : 30px;//パディング:gridlexでも使用
$tablet-break    : 768px;//タブレットでのブレイクポイント
$mobile-break    : 568px;//モバイルでのブレイクポイント
$padding-break   : 40px;//基本のコンテンツ余白
$padding-content : ($padding-break / 2);//テーブルの上下などの余白指定

//----------------------------------------------------------------
//    header fixed設定
//----------------------------------------------------------------
$is-fixed        : true;
$headerHeight    : 88px;
$headerHeight-sp : 60px; //ここを変えたら、menu.jsの「headerHeight」の値も変えること！


/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/

//全体をレスポンシブ非対応にする時は false
$responsiveMode : true;


// max-width(gridlexに合わせる)
$breakpoint-max: (
  'xs': 'screen and (max-width: 568px)', //スマホ境界
  'sm': 'screen and (max-width: 768px)', //タブレット境界
  'md': 'screen and (max-width: 1024px)',
  'content': 'screen and (max-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (max-width: 1280px)',
  'pc': 'screen and (max-width: 1366px)',
  'pcW': 'screen and (max-width: 1800px)',
) !default;

// min-width(gridlexに合わせる)
$breakpoint-min: (
  'xs': 'screen and (min-width: 568px)', //スマホ境界
  'sm': 'screen and (min-width: 768px)', //タブレット境界
  'md': 'screen and (min-width: 1024px)',
  'content': 'screen and (min-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (min-width: 1280px)',
  'pc': 'screen and (min-width: 1366px)',
  'pcW': 'screen and (min-width: 1800px)',
) !default;


/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/

$white           : #FFFFFF;
$gray            : #CCCCCC;
$black           : #000000;
$gray-l          : lighten( $gray, 15%);
$red             : #c82e2e;

$keyColor        : #0a7b5c; //キーカラー
$keyColorDark    : #046548; //キーカラー濃いめ
$keyColorDarker  : #0b453a; //キーカラーより濃いめ
$keyColorLight   : #E6EDDC; //キーカラーより明るめ
$keyColorLighter : #42D877; //より明るめ

$accentColor     : $red; //アクセントカラー

$bg              : #e8f6ee;
$bg-gray         : #e3e3e3;
$bg-green        : #F1F5EA;

$line            : #9cd891;

$bdrColor        : $gray;
$box-shadow      : 0 0 10px rgba(0, 0, 0, .16);

/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/

$FontAwesome: 'Font Awesome 5 Free';

$textColor: #212c2b;
$textColorLight: lighten($textColor,10);
$textColorDark: darken($textColor,10);
$textColorComment: #4b5d5b;
$lineHeight: 1.5; // 基本的な行間

// サンセリフ・ゴシック体
$sanserif: YakuHanJPs, "Noto Sans CJK JP", "Noto Sans JP", 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
// セリフ・明朝体
$serif: "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

// title後ろの装飾文字
// $titleFont: 'EB Garamond';
$titleFont: 'EB Garamond', serif;

//----------------------------
//    コンテンツ
//---------------------------
$content-bg       : $white;
$font-size        : 190%;//全体のフォントサイズ
$full-size   : 100%;//基本のコンテンツ余白

//-----------------------------
//    装飾の定義
//-----------------------------
$border-radius   : 3px;//全体の角丸サイズ
$transition: .2s ease;
$border-color   : $line;

//----------------------------------------------------------------
//    ボタンの定義
//----------------------------------------------------------------
$btn-width       : 350px;//PCサイトアクションボタンの大きさ
$border-btn          : none;//テーブルのボーダーカラー
$border-btn-width   : 0px;//テーブルのボーダーサイズ
$border-txt-color   : $white;//テーブルのボーダーサイズ
