.l-f {
  background-color: #E3E3E3;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 50px 20px 60px 20px;
    display: flex;
    flex-direction: column;

    & > h2 {
      font-size: 30px;
      letter-spacing: .06em;
      line-height: 1.7;
      margin-bottom: 5px;
      @include mq-max(sm) {
        font-size: 24px;
      }
    }
    @include mq-max(content) {
      padding: 50px 20px;
    }
    @include mq-max(sm) {
      padding-top: 35px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 0px;

    }
    @include mq-max(xs) {
      flex-wrap: wrap;
    }
    &--none{
      display: none;
    }
  }
  &__info {
    display: block;
  }
  &__logo {
    > img {
      width: auto;
    }
  }
  &__nav {
    display: flex;
  }
  &__links {
    margin-left: 50px;
    @include mq-max(xs) {
      margin-left: 0;
      margin-bottom: 30px;
    }
    &:first-child {
      margin-left: 0;
    }
    @include mq-max(xs) {
      width: 50%;
    }
    & > li {
      a {
        display: block;
        padding: 2px 0;
      }
    }
  }

  //////////////////////////////////////
  //Group
  //////////////////////////////////////
  &__group-wrap{
    margin-bottom: auto;
    @include mq-max(sm) {
      padding-bottom: 45px;
    }
    & > .l-f__group-item{
      &:last-child{
        padding-bottom: 0;
      }
    }
  }
  &__group-item{
    @include mq-max(sm) {
      padding-bottom: 20px;
    }
    > a {
      max-width: 360px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
      color: $textColor;
      border-bottom: 2px solid $textColor;
      font-weight: 500;
      letter-spacing: .06em;
      line-height: 2;
      @include mq-max(md) {
        margin-right: auto;
        margin-left: auto;
        font-size: 15px;
        padding-bottom: 10px;
        padding-top: 0;
      }
      &:hover{
        color: $keyColor;
        border-bottom: 2px solid $keyColor;
      }
      &[target="_blank"]{
        &:after{
          @include fa('f24d');
          font-weight: 500;
          margin-left: 10px;
        }
      }
    }
  }
  //////////////////////////////////////
  //Sitemap
  //////////////////////////////////////
  &__sitemap{
    display: flex;
    justify-content: center;
    padding: 30px 0 30px;
    background: $keyColor;
    @include mq-max(sm) {
      padding: 30px 20px;
    }
  }
  &__sitemap-lists{
    display: flex;
    @include mq-max(sm) {
      display: block;
      width: 100%;
      flex-direction: column;
    }
  }
  &__sitemap-item{
    @include mq-max(sm) {
      display: inline-block;
    }
    & > a{
      font-size: 12px;
      color: $white;
      &:hover{
        opacity: .7 !important;
        color: $white;
      }
    }
    &:after{
      content: "|";
      display: inline-block;
      margin: 0 10px;
      color: $white;
    }
    &:last-child{
      &:after{
        content: none;
      }
    }
  }
  //////////////////////////////////////
  //Copyright
  //////////////////////////////////////

  &__copyright {
    text-align: center;
    display: block;
    color: $textColor;
    background-color: $white;
    padding: 10px 0;
    margin-right: 80px;
    @include mq-max(sm) {
      margin-right: 0;
      font-size: 12px;
      padding: 0;
    }
  }
  // copy & logo wrap
  &__copyright-wrap{
    padding: 20px;
    background: $white;
  }
  // copyとlogoが入っているインナー
  &__copyright-inner-wrap{
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: $white;
    // タブレット表示時
    @include mq-max(sm) {
      // padding: 20px;
      height: 56px;
      flex-flow: column-reverse;
    }
    // 外部リンク用のアイコン削除
    & > a{
      &:after {
        content: none;
      }
    }
    //ロゴのサイズ変更
    & > img{
      @include mq-max(sm) {
        width: 230px;
        margin-bottom: 10px;
      }
    }
  }
}


// .l-f {
//   background-color: #E3E3E3;
//   &__inner {
//     max-width: $contentWidth;
//     width: 100%;
//     margin: 0 auto;
//     padding: 50px 0;
//     display: flex;
//     flex-direction: column;
//     @include mq-max(content) {
//       padding: 50px 20px;
//     }
//     @include mq-max(xs) {
//       flex-wrap: wrap;
//       margin-bottom: -30px;
//     }
//     &--none{ //トップ以外のページで非表示
//       display: none;
//     }
//   }
//   // footerに入っているリンク一覧
//   &__group-wrap{
//     margin-bottom: auto;
//     margin: 0 15px;
//   }
//   &__group-item{
//     &>a{
//       color: $textColor;
//       line-height: 2;
//       letter-spacing: .06em;
//       max-width: 360px;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       padding: 10px 0px;
//       border-bottom: 2px solid $textColor;
//       font-weight: 500;
//       &:hover{
//         color: $keyColor;
//         border-bottom: 2px solid $keyColor;
//       }
//       &[target="_blank"]{
//         &:after{
//           @include fa('f24d');
//           font-weight: 500;
//           margin-left: 10px;
//         }
//       }
//       @include mq-max(md) {
//         margin-right: auto;
//         margin-left: auto;
//       }
//     }
//   }
//   &__logo {
//     > img {
//       width: auto;
//     }
//   }
//   //////////////////////////////////////
//   //Sitemap
//   //////////////////////////////////////
//   &__sitemap{
//     display: flex;
//     justify-content: center;
//     padding: 30px 0 30px;
//     background: $keyColor;
//     @include mq-max(sm) {
//       padding: 30px 20px;
//     }
//   }
//   &__sitemap-lists{
//     display: flex;
//     @include mq-max(sm) {
//       display: block;
//       width: 100%;
//       flex-direction: column;
//     }
//   }
//   &__sitemap-item{
//     @include mq-max(sm) {
//       display: inline-block;
//     }
//     & > a{
//       font-size: 12px;
//       color: $white;
//       &:hover{
//         opacity: .7;
//         color: $white;
//       }
//     }
//     &:after{
//       content: "|";
//       display: inline-block;
//       margin: 0 10px;
//       color: $white;
//     }
//     &:last-child{
//       &:after{
//         content: none;
//       }
//     }
//   }

//    //////////////////////////////////////
//   //Copyright
//   //////////////////////////////////////
//   &__copyright {
//     text-align: center;
//     display: block;
//     color: $textColor;
//     background-color: $white;
//     padding: 10px 0;
//     margin-right: 80px;
//     @include mq-max(sm) {
//       margin-right: 0;
//       font-size: 12px;
//       padding: 0;
//     }
//   }
//   // copy & logo wrap
//   &__copyright-wrap{
//     padding: 20px;
//     background: $white;
//   }
//   // copyとlogoが入っているインナー
//   &__copyright-inner-wrap{
//     height: 70px;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     background: $white;
//     // タブレット表示時
//     @include mq-max(sm) {
//       // padding: 20px;
//       height: 56px;
//       flex-flow: column-reverse;
//     }
//     // 外部リンク用のアイコン削除
//     & > a{
//       &:after {
//         content: none;
//       }
//     }
//     //ロゴのサイズ変更
//     & > img{
//       @include mq-max(sm) {
//         width: 230px;
//         margin-bottom: 10px;
//       }
//     }
//   }
// }
