// 下層全体に適用するCSS
//import from module
//ぱんくず
@import "../module/topicsPath";

//下層タイトル
.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 200px;
  background-image: url("../../image/common/bg_lowerCap.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;

  &__title {
    @extend %v_center;
    color: $textColor;
    max-width: $contentWidth;
    padding: 0 15px;
    width: 100%;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.97;
    letter-spacing: .06em;
    @include mq-max(sm){
      font-size: 32px;
      width: 100%;
      // padding: 0 20px;
    }
    @include mq-max(xs){
      font-size: 28px;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  下層

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-lower {}
