.u- {
  &bdr {
    &--keyColor {
      background: linear-gradient(transparent 60%, $keyColor 60%);
    }
    &--yellow {
      background: linear-gradient(transparent 60%, #FFEF69 60%);
    }
  }

  &txt {
    color: $textColor;
    &--red {
      color: $red;
    }
    &--largest {
      line-height: 3;
      font-size: 120%;
    }
  }
}

// text
.c-txt{
  &__sign{ //署名
    text-align: right;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: .06em;
    line-height: 1.45;
    & >small{
      font-size: 16px;
      line-height: 1.5;
    }
  }
  &__end{ //署名
    text-align: right;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: .06em;
    line-height: 1.45;
    & >small{
      font-size: 16px;
      line-height: 1.5;
    }
  }
}