//下層読み込みSCSS
@import "../util/all";
@import "../base/lower";

.c-cvArea {
  // title
  &__ttl{
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: .06em;
    line-height: 1.5;
    @include mq-max(sm) {
      font-size: 18px;
    }
  }
  // text
  &__txt{
    font-weight: 500;
    line-height: 1.625;
    margin-bottom: 0;
    @include mq-max(md) {
      margin-bottom: 14px;
    }
  }
  // ボタンが入ったボックス
  &__btn-box{
    width: 60%;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    @include mq-max(xs) {
      width: 80%;
    }
  }
  // tel
  &__tel{
    font-size: 31px;
    font-weight: 700;
    letter-spacing: .06em;
    line-height: 1.65;
    margin-bottom: 14px;
    & >span{
      &:before{
        @include fa('f095');
        margin-right: 10px;
      }
      & >a {
        color: $white;
      }
    }
  }
  // SP時に出現(classはjsで制御)
  &__tel-sp{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: .06em;
    line-height: 1.65;
    margin-bottom: 14px;
    @include mq-max(md) {
      display: block;
    }
    .c-btn{
      font-size: 22px;
      max-width: inherit;
      @include mq-max(sm) {
        font-size: 14px;
      }
      & >span{
        color: $white;
        &:before{
          @include fa('f095');
          margin-right: 10px;
        }
      }
    }
  }
  // メール
  &__mail{
    color: $white;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .06em;
    line-height: 1.65;
    margin-bottom: 0;
    padding: 19px 30px 19px 35px;
    display: inline-block;
    width: auto;
    max-width: inherit;
    & > span{
      &:before{
        @include fa('f0e0');
        color: $white;
        position: absolute;
        left: -1.2em;
        top: 2px;
      }
    }
    @include mq-max(md) {
      display: block;
    }
    @include mq-max(sm) {
      font-size: 14px;
      padding: 14px 30px 14px 20px;
    }
  }
  &__mail-wrap{
    display: flex;
    justify-content: center;
    @include mq-max(md) {
      display: block;
    }
  }
}


// color 調整用
a.c-cvArea__mail{
  color: $white;
}