
.c-box {
  background: $white;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 30px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0
  }
  @include mq-max(sm) {
    padding: 0 0 24px;
    margin-bottom: 30px;
  }
  &__inner{
    padding: 30px;
    @include mq-max(sm) {
      padding: 15px;
    }
  }
  // borderなし
  &--simple{
    border: none;
  }
}

// 画像が入ったボックス
.c-img-box{
  // 中央ぞろえ
  &--center{
    display: flex;
    justify-content: center;
    align-items: center;
    & > img{
      max-width: 300px;
      display: inline;
    }
  }
  // ホールディングス背景
  &--hld{
    background-image: url("/inc/image/top/bg_hirogin-hld.png");
    background-size: cover;
    width: 100%;
    border-bottom: 1px solid $gray;
    border-top: 1px solid $gray;
  }
  &__link{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding: 50px 0;
    @include mq-max(md) {
      padding: 30px 0;
    }
    @include mq-max(sm) {
      padding: 20px 0;
    }
    &:after{
      content: none;
    }
    &> img{
      width: 100%;
      max-width: 800px;
    }
  }

}


// リンク調整
a[target="_blank"]{
  &.c-img-box__link{
    &:after{
      content: none;
    }
  }
}