.pageTop{
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 1000;
  box-shadow: $box-shadow;
  width: 68px;
  height: 68px;
  background: $white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img{
    width: 19px;
    height: 8px;
  }
  @include mq-max(xs) {
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
  }
}
