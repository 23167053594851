.c-list {
  list-style-type: none;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 30px;
  line-height: 2;
  &:last-child {
    margin-bottom: 0;
  }
  // 丸付き
  &--disc {
    & > .c-list__item {
      position: relative;
      &:before {
        content: '・';
        display: inline-block;
        color: $keyColor;
        position: absolute;
        top: 0;
        left: -1em;
        font-size: 1.5em;
        line-height: 1.5;
        @media all and (-ms-high-contrast: none) {
          top: -0.1em;
        }
      }
    }
  }
  // 四角付き
  &--sq{
    & > .c-list__item {
      position: relative;
      &:before {
        content: '';
        padding: 0.3em;
        display: inline-block;
        background: $keyColor;
        position: absolute;
        top: .37em;
        left: -1em;
        font-size: 1.5em;
        line-height: 1.5;
      }
      &:after {
        content: '';
        padding: .09em;
        display: inline-block;
        background-color: $bg;
        position: absolute;
        top: 0.58em;
        left: -0.77em;
        font-size: 1.5em;
        line-height: 1.5;
      }
    }
  }
  // 数字をカウント
  &--count {
    counter-reset: count;
    & > .c-list__item {
      position: relative;
      &:before{
        counter-increment: count;
        content:counter(count)".";
        position: absolute;
        left: -1.1em;
        color: $textColor;
      }
    }
    &--brackets{
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        &:before{
          counter-increment: count;
          content:"("counter(count)")";
          position: absolute;
          left: -1.5em;
          color: $keyColor;
        }
      }
    }
    &--round{
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        &:before{
          counter-increment: count;
          content: counter(count);
          position: absolute;
          left: -1.5em;
          color: $textColor;
        }
        &:after{
          content: "";
          border: 1px solid $textColor;
          border-radius: 50%;
          position: absolute;
          left: -1.85rem;
          top: 6.3px;
          width: 1.3em;
          height: 1.3em;
        }
      }
    }
  }
  &--comment {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
    & > .c-list__item {
      position: relative;
      font-size: 14px;
      color: $textColorComment;
      line-height: 1.5;
      letter-spacing: 0;
      &:before{
        content: '※';
        display: inline-block;
        position: absolute;
        left: -1.5em;
        @media all and (-ms-high-contrast: none) {
          top: 0.1em;
        }
      }
    }
    // 番号付き注釈
    &--num {
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        padding-left: 0.5em;
        &:before {
          counter-increment: count;
          content: '※'counter(count);
          position: absolute;
          left: -1.5em;
          @media all and (-ms-high-contrast: none) {
            top: 0;
          }
        }
      }
    }
  }
}